import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(): void {
    window?.sessionStorage.clear();
  }

  remove(key: string = 'USER_KEY') {
    window?.sessionStorage.removeItem('USER_KEY');
  }

  public save(token: any): void {
    window.sessionStorage.removeItem('USER_KEY');
    window.sessionStorage.setItem('USER_KEY', JSON.stringify(token));
  }

  public getToken(): any {
    const user = window.sessionStorage.getItem('USER_KEY');
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('USER_KEY');
    if (user) {
      return true;
    }

    return false;
  }

}
